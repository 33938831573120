<template>
  <div class="card-title">{{ text }}</div>
</template>

<script>
export default {
  name: 'cardTitle',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.card-title {
  box-sizing: border-box;
  font-size: 32px;
  line-height: 36px;
  color: #151C39;
  font-weight: 700;
  padding: 0px 20px;
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    position: absolute;
    height: 20px;
    width: 100%;
    background: linear-gradient( 180deg, #FFDE6D 0%, #FFD032 100%);
    left: 0px;
    bottom: -10px;
    z-index: -1;
    border-radius: 10px;
  }
}
</style>
