<template>
  <div class="order-info" :class="handFlag">
    <div v-if="handFlag === 'xq'" class="order-number-text">订单编号：{{ deliveryInfo.orderId }}</div>
    <card-title text="餐品信息" />
    <div class="dish-list-box">
      <div class="dish-pac-item" v-for="(item, index) in dishInfo" :key="'cpxx' + index">
        <div class="index-text">{{ index + 1 }}</div>
        <div class="dish-img">
          <img v-if="item.imageUrl" class="img" :src="item.imageUrl" />
          <img v-else class="img" src="../img/zwtp.png" />
        </div>
        <div class="title-sub-text">
          <div class="title-text">{{ ['', void 0, null, NaN].includes(item.dishId) ? item.packageName : item.dishName }}</div>
          <div class="quantity-text">× {{ item.quantity }}</div>
        </div>
        <div class="price-text"><span class="fh">¥</span> {{ item.price }}</div>
      </div>
      <div class="quantity-total-price" v-if="handFlag === 'xq'">
        <div class="dish-number-text">共 {{ totalQuantity }} 份菜品</div>
        <div class="price-total-text">
          总计：<span class="price-text">¥ {{ deliveryInfo.orderAmount }}</span>
        </div>
      </div>
    </div>
    <card-title text="配送信息" />
    <van-form class="delivery-info-form" validate-first @failed="onFailed">
      <van-field
        v-model="deliveryInfo.userName"
        label="订餐人"
        :placeholder="handFlag === 'xq' ? '' : '请输入订餐人'"
        input-align="right"
        :readonly="handFlag === 'xq'"
        :right-icon="handFlag === 'zf' ? 'edit' : ''"
      />
      <van-field
        v-model="deliveryInfo.mobileNum"
        label="联系电话"
        :placeholder="handFlag === 'xq' ? '' : '请输入联系电话'"
        input-align="right"
        :readonly="handFlag === 'xq'"
        :right-icon="handFlag === 'zf' ? 'edit' : ''"
      />
      <van-field
        v-if="!noHouseAddress"
        :value="deliveryInfo.houseAddress"
        label="取餐地址"
        :placeholder="handFlag === 'xq' ? '' : '请输入取餐地址'"
        input-align="right"
        :right-icon="handFlag === 'zf' ? 'arrow' : ''"
        :readonly="true"
        @click="qcdzOnClick"
      />
      <van-field
        :value="qcsjText"
        label="取餐时间"
        :placeholder="handFlag === 'xq' ? '' : '取餐时间'"
        input-align="right"
        :right-icon="handFlag === 'zf' ? '' : ''"
        readonly
      />
    </van-form>
    <!-- <card-title text="支付方式" />
    <van-radio-group class="zffs-box" v-model="paymentMode" v-if="handFlag === 'zf'">
      <van-cell-group>
        <van-cell title="微信" icon="wechat-pay" clickable>
          <template #right-icon>
            <van-radio name="wx" shape="square" />
          </template>
        </van-cell>
        <van-cell title="支付宝" icon="alipay" clickable>
          <template #right-icon>
            <van-radio name="zfb" shape="square" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <van-form class="delivery-info-form" validate-first v-if="handFlag === 'xq'">
      <van-field
        value="微信支付"
        label="支付方式"
        placeholder=""
        input-align="right"
        readonly
      />
    </van-form> -->

    <van-popup v-model="houAddPopShow" round position="bottom" safe-area-inset-bottom>
      <van-picker show-toolbar :columns="houseAddressOptions" @cancel="houAddPopShow = false" @confirm="houAddOnConfirm" />
    </van-popup>
    <van-popup v-model="popupShow" round position="bottom" safe-area-inset-bottom>
      <van-datetime-picker
        v-model="deliveryPopupTime"
        type="datetime"
        title="选择取餐时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="datetimeOnConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import cardTitle from './cardTitle.vue';
import Vue from 'vue';
import { Cell, CellGroup, Form, Field } from 'vant';
import moment from 'moment';
import Big from 'big.js';

moment.locale('zh-cn');
Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: 'orderInfo',
  components: {
    cardTitle
  },
  props: {
    handFlag: {
      type: String,
      default: 'zf'
    },
    dishInfo: {
      type: Array,
      default: () => []
    },
    deliveryInfo: {
      type: Object,
      default: () => ({})
    },
    paymentMode: {
      type: [String, Number],
      default: 'wx'
    },
    deliveryTime: {
      type: String,
      default: ''
    },
    houseAddress: {
      type: String,
      default: ''
    },
    noHouseAddress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      houseAddressOptions: ['井亭家园北区南门岗处', '万科金色城市一期东门岗处'],
      houAddPopShow: false,
      popupShow: false,
      minDate: new Date(),
      maxDate: null,
      deliveryPopupTime: null
    };
  },
  computed: {
    totalQuantity() {
      return this.dishInfo.map(item => +item.quantity).reduce((prev, next) => Big(prev).plus(next).toNumber(), 0);
    },
    totalPrice() {
      return this.dishInfo
        .map(item => Big(item.quantity).times(item.price).toNumber())
        .reduce((prev, next) => Big(prev).plus(next).toNumber(), 0);
    },
    qcsjText() {
      if (this.deliveryInfo.supplyTimeStart && this.deliveryInfo.supplyTimeEnd) {
        return (
          this.deliveryInfo.deliveryTime +
          ' ' +
          this.deliveryInfo.supplyTimeStart.slice(0, 5) +
          '~' +
          this.deliveryInfo.supplyTimeEnd.slice(0, 5)
        );
      } else {
        return this.deliveryInfo.deliveryTime + ' 全天';
      }
    }
  },
  created() {
    // this.dishInfo.map(item => [item.kdsdStart + '~' + item.kdsdEnd, item.supplyTimeStart + '~' + item.supplyTimeEnd])
    // if (this.handFlag === 'zf') {
    //   // const dcsj =  sessionStorage.getItem('jingting_dining_car_time');
    //   // this.minDate = new Date(dcsj + ' 00:00:00' || null);
    //   // this.maxDate = new Date(dcsj + ' 23:59:59' || moment().add(7, 'd'));
    //   // this.deliveryPopupTime = new Date(Math.max(...this.dishInfo.map(item => new Date(item.supplyTimeStart).getTime())));
    //   // this.$emit('update:deliveryTime', moment(this.deliveryPopupTime).format('YYYY-MM-DD HH:mm'));
    // }
  },
  methods: {
    onFailed() {},
    qcdzOnClick() {
      if (this.handFlag === 'zf') {
        this.houAddPopShow = true;
      }
    },
    qcsjOnClick() {
      if (this.handFlag === 'zf') {
        this.popupShow = true;
      }
    },
    datetimeOnConfirm(value) {
      this.$emit('update:deliveryTime', moment(value).format('YYYY-MM-DD HH:mm'));
      this.popupShow = false;
    },
    houAddOnConfirm(value) {
      this.$emit('update:houseAddress', value);
      this.houAddPopShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.order-info {
  box-sizing: border-box;
  .card-title {
    margin-bottom: 30px;
  }
}
.order-number-text {
  box-sizing: border-box;
  border: 1px solid #ffbe00;
  background-color: rgba(255, 190, 0, 0.1);
  height: 60px;
  border-radius: 8px;
  font-size: 28px;
  line-height: 32px;
  color: #ffbe00;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.dish-list-box {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
  padding: 36px 40px;
  border-radius: 16px;
  margin-bottom: 20px;
  width: 100%;
  .dish-pac-item {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .index-text {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
      margin-right: 32px;
      color: #ffffff;
      font-size: 28px;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dish-img {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 80px;
      height: 80px;
      border-radius: 10px;
      margin-right: 28px;
      .img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
    }
    .title-sub-text {
      box-sizing: border-box;
      flex-grow: 1;
      width: calc(100% - 180px - 200px);
      .title-text {
        box-sizing: border-box;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 28px;
        color: #333333;
        font-weight: 700;
      }
      .quantity-text {
        box-sizing: border-box;
        font-size: 24px;
        line-height: 28px;
        color: #818181;
        margin-top: 10px;
      }
    }
    .price-text {
      box-sizing: border-box;
      flex-shrink: 0;
      margin-left: 10px;
      font-size: 32px;
      line-height: 44px;
      color: #e02020;
      font-weight: 700;
      .fh {
        font-size: 24px;
        line-height: 44px;
      }
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  .quantity-total-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    .price-text {
      color: #e02020;
      font-weight: 700;
    }
  }
}
.delivery-info-form {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
  overflow: hidden;
  margin-bottom: 20px;
}
.zffs-box {
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
  overflow: hidden;
  .van-icon-wechat-pay {
    color: #4cc851;
  }
  .van-icon-alipay {
    color: #2076f8;
  }
}
</style>
